import webapi from '@/core/webapi';
import CONSTANTS from '@/constants';

const CLIENT_URI = `${CONSTANTS.API_BASE}/user/clients`;
const USER_URI = `${CONSTANTS.API_BASE}/user/manage`;
const PA_URI = `${CONSTANTS.API_BASE}/gateway/product-analytics`;

export default class ManageAPI {
    // CLIENTS
    static async listClients() {
        const uri = `${CLIENT_URI}`;
        return webapi.get({ uri });
    }

    static async getClient(clientId: Number) {
        const uri = `${CLIENT_URI}/${clientId}`;
        return webapi.get({ uri });
    }

    static async createClient(payload: Record<string, any>) : Promise<any> {
        const uri = `${CLIENT_URI}`;
        const body = payload;
        return webapi.post({ uri, body });
    }

    static async updateClient(clientId: Number, payload: Record<string, any>) : Promise<any> {
        const uri = `${CLIENT_URI}/${clientId}`;
        const body = payload;
        return webapi.put({ uri, body });
    }

    static async deleteClient(clientId: Number) {
        const uri = `${CLIENT_URI}/${clientId}`;
        return webapi.delete({ uri });
    }

    // USERS
    static async listUser() {
        const uri = `${USER_URI}`;
        return webapi.get({ uri });
    }

    static async getUser(userId: Number) {
        const uri = `${USER_URI}/${userId}`;
        return webapi.get({ uri });
    }

    static async createUser(payload: Record<string, any>) : Promise<any> {
        const uri = `${USER_URI}`;
        const body = payload;
        return webapi.post({ uri, body });
    }

    static async updateUser(userId: Number, payload: Record<string, any>) : Promise<any> {
        const uri = `${USER_URI}/${userId}`;
        const body = payload;
        return webapi.put({ uri, body });
    }

    static async deleteUser(userId: Number) {
        const uri = `${USER_URI}/${userId}`;
        return webapi.delete({ uri });
    }
}
