<template>
    <v-dialog
        v-model="activated"
        width="500"
    >
        <v-card>
            <v-card-title class="headline grey lighten-2">
                Client
            </v-card-title>

            <v-card-text>
                <v-row>
                    <v-col>
                        <i-text-field v-model="client.name" label="Name" />
                    </v-col>
                    <v-col>
                        <i-text-field v-model="client.key" label="Key" />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    text
                    @click="close"
                >
                    Close
                </v-btn>
                <v-btn color="primary" @click="saveClient">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<script>
    import ManageAPI from '../webapi';

    export default {
        name: 'ManageClientForm',

        props: {
            activated: {
                type: Boolean,
                default: false,
            },
            clientId: {
                type: String,
                default: undefined,
            },
        },

        created() {
            this.getClient();
        },

        data: () => ({
            client: {},
        }),

        watch: {
            activated(newValue) {
                if (newValue === true) {
                    this.getClient();
                }
            },
        },

        methods: {
            getClient() {
                if (this.activated === true && this.clientId !== undefined) {
                    ManageAPI.getClient(this.clientId).then((data) => {
                        this.client = data;
                    });
                }
            },
            saveClient() {
                if (this.clientId !== undefined) {
                    ManageAPI.updateClient(this.clientId, this.client).then(() => {
                        this.client = {};
                        this.close();
                    });
                } else {
                    ManageAPI.createClient(this.client).then(() => {
                        this.client = {};
                        this.close();
                    });
                }
                this.$emit('on-save');
            },
            close() {
                this.$emit('on-close');
            },
        },
    };
</script>
