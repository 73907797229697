<template>
    <request :request="request" @on-success="onListClientRequestSuccess">
        <v-row>
            <v-col class="text-left" cols=12>
                <v-btn color="primary" @click="createClient(undefined)"><v-icon small left>$add</v-icon> Add Client</v-btn>
            </v-col>
            <v-col>
                <v-card>
                    <v-card-title>Clients</v-card-title>
                    <v-simple-table class="text-left">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Key</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="client in clients"
                                    :key="client.key"
                                >
                                    <td>{{ client.name }}</td>
                                    <td>{{ client.key }}</td>
                                    <td>
                                        <v-btn
                                            icon
                                            small
                                            @click="createClient(client.key)">
                                            <v-icon small>$edit</v-icon>
                                        </v-btn>
                                        <i-delete-btn
                                            title="Are you sure to delete the client?"
                                            :payload="{clientId: client.key}"
                                            @on-confirm="clientDelete"/>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>
        <client-form
            :activated="formActivated"
            @on-close="formActivated=false; selectedClient=undefined"
            @on-save="clientSaved"
            :clientId="selectedClient" />
    </request>
</template>

<script>
    import ManageAPI from '../webapi';
    import ClientForm from '../components/ClientForm.vue';

    export default {
        name: 'ManageClientList',

        components: {
            ClientForm,
        },

        data: () => ({
            request: () => ManageAPI.listClients(),
            clients: [],
            selectedClient: undefined,
            formActivated: false,
        }),

        methods: {
            onListClientRequestSuccess(data) {
                this.clients = data;
            },
            createClient(clientId) {
                this.selectedClient = clientId;
                this.formActivated = true;
            },
            clientSaved() {
                this.selectedClient = undefined;
                this.request = () => ManageAPI.listClients();
            },
            clientDelete(payload) {
                ManageAPI.deleteClient(payload.clientId).then(() => {
                    this.request = () => ManageAPI.listClients();
                });
            },
        },
    };
</script>
